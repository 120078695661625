@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f;
}

.app {
  width: 90%;
  max-width: 43rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: white;
  margin: 3rem auto;
}

.form-control {
  margin-bottom: 1rem;
}

.form-control input,
.form-control label {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}

.form-control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control-group {
  display: flex;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  flex-wrap: wrap;
}

.control-group .form-control {
  min-width: 15rem;
  flex: 1 1;
}

button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #33059e;
  border-color: #33059e;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}

